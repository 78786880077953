<template>
  <VFlex v-bind="$attrs">
    <VCard class="cta-box text-xs-center elevation-0 ma-2 py-5 px-2">
      <VFlex pt-5 xs12>
        <BaseSiteContent
          :content-key="`cta_${ctaKey}_title`"
          :default-content="title"
          :tag-class="[
            'cta--text',
            'bluegiant-red--text',
            'mt-0',
            [('xs', 'sm')].includes($mq) ? 'title' : 'header-2',
          ]"
          tag="h2"
        />
      </VFlex>
      <VFlex pb-5 xs12>
        <BaseButton color="bluegiant-blue" :href="href" v-on="$listeners">{{
          buttonText
        }}</BaseButton>
      </VFlex>
    </VCard>
  </VFlex>
</template>

<script>
export default {
  name: 'CTAbox',
  components: {},
  props: {
    title: {
      type: String,
      default: 'Hey there!',
    },
    buttonText: {
      type: String,
      required: true,
      default: 'Go',
    },
    ctaKey: {
      type: Number,
      default: 1,
    },
    href: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@design';
.theme--light.cta-box {
  height: calc(100% - 20px); // this is to fake fill-height minus border radius
  border: solid 10px $color-bluegiant-red;
  border-radius: 0;
}
.cta--text {
  display: inline-block;
}
</style>
